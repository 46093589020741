import React, { useState } from "react";
import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import readXlsxFile from "read-excel-file";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import {
  UploadAssessmentHeader,
  UploadAssessmentButton,
  UploadAssessmentContainer,
  AssesmentButton,
  UploadAssessMentButtonWrapper,
} from "./Modal.style";
import exportFile from '../../sample.xlsx'
import QuestionParser from "../QuestionParser/questionParser";
import { useAuth } from "../../Auth/Auth";

function UploadAssessmentComponent({ setModal, handleUploadAssessment }) {
  const [file, setFile] = useState("");
  const [question, setQuestions] = useState([]);
  const [fileName, setFileName] = useState("");
  const { DisplaySnackbar } = useAuth();

  const fileUploadHandler = async (e) => {
    setFile(e.target.files[0]);
    let files = e.target.files[0];
    //console.log(files);

    const nameList = files.name.split(".");
    const type = nameList[nameList.length - 1];
    let ques = [];
    if (type === "xlsx") {
      await readXlsxFile(files).then(async (rows) => {
        let headers = rows[0];
        for (let index = 1; index < rows.length; index++) {
          const row = rows[index];
          let newObj = {};
          for (let i in headers) {
            newObj[headers[i]] = row[i];
          }
          const newObject = new QuestionParser(newObj).parseQuestion(index);
          ques.push(newObject);
        }
        setFileName(files.name);
      });
    } else if (type === "csv") {
      const reader = new FileReader();
      reader.readAsText(files);
      reader.onload = async (e) => {
        const rows = e.target.result.split("\n");
        let headers = rows[0].replace("\r", "").split(",");
        console.log(rows);
        rows.forEach(async (row, index) => {
          row.replace("\r", "");
          row = row.split(",");
          if (index !== 0) {
            let newObj = {};
            for (let i in headers) {
              newObj[headers[i]] = row[i];
              // console.log(row[i]);
            }
            try {
              const newObject = new QuestionParser(newObj).parseQuestion(index);
              ques.push(newObject);
              // console.log(newObject);
            } catch (err) {
              console.log(err);
            }
          }
        });
        setFileName(files.name);
      };
    } else {
      DisplaySnackbar("Upload .csv or xlsx sheet", "error");
      setFile("");
      setFileName("");
    }
    setQuestions(ques);
  };
  console.log(file);
  console.log("in Upload aseesment");
  const handleUploadFile = () => {
    if (!question.length) {
      DisplaySnackbar("Your file doesn't have any Question", "error");
      return;
    }
    handleUploadAssessment(question);
  };
  return (
    <ModalWrapper>
      <ModalContainer style={{ minWidth: "350px", minHeight: "200px" }}>
        <UploadAssessmentHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "1.1rem",
            }}
          >
            <CloudUploadOutlinedIcon className="icon" />
            UPLOAD QUESTIONS
          </div>
          <div>
            <a href={exportFile} download style={{textDecoration: "none"}}>
              <UploadAssessmentButton>
                <ArrowDownwardOutlinedIcon className="icon" />
                Export File
              </UploadAssessmentButton>
            </a>
          </div>
        </UploadAssessmentHeader>
        <UploadAssessmentContainer>
          <div className="text">File</div>
          <div>
            <label for="uploadFile">
              <span className="firstLabel file">FILE</span>
              <span className="secondLabel file">
                {`${fileName ? fileName : "NO SELECTED FILE"}`}{" "}
              </span>
            </label>
            <input
              type="file"
              id="uploadFile"
              onChange={(e) => fileUploadHandler(e)}
              style={{ display: "none" }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
          <div className="text">
            Upload <span style={{ color: "#5CE0D2" }}>.xlsx</span>
          </div>
        </UploadAssessmentContainer>
        <UploadAssessMentButtonWrapper>
          <AssesmentButton onClick={() => setModal(false)}>
            Cancel
          </AssesmentButton>
          <AssesmentButton onClick={() => handleUploadFile()}>
            Upload
          </AssesmentButton>
        </UploadAssessMentButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default UploadAssessmentComponent;
