import styled from "styled-components";
export const QuestionWrapper = styled.div`
  max-width: 1000px;
  min-height: 257px;
  background-color: #fff;
  box-shadow: 0 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
  border-left: 11px solid #4de3d4;
  box-sizing: border-box;
  margin: 60px auto;
  padding: 10px 15px;
  // position:relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  // max-height:700px;

  .questionContainer {
    display: flex;
    max-height: 500px;
    align-items: center;
  }
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const QuestionType = styled.div`
  display: flex;
`;

export const QuestionTypeChild = styled.button`
  width: 130px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-radius: 8px;
  background-color: #5ce0d2;
  color: #fff;
  border: none;
  margin-left: 10px;
  max-height: 40px;
  box-sizing: border-box;
`;

export const QuestionText = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`;

export const WrapOptions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 19px;
  .optioncontainer {
    display: flex;
    margin: 13px 0;
  }
`;

export const QuestionFooter = styled.div`
  display: flex;
  justify-content: space-between;

  .firstDiv {
    height: 50px;
    width: 130px;
    padding-left: 20px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 7px rgb(33 33 33 / 18%);
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .icons {
    color: #5ce0d2;
    display: flex;
    align-items: center;
    img {
      margin-left: 18px;
    }
  }
`;
export const Option = styled.div`
  padding: 9px;
  width: 60%;

  display: flex;

  ${({ isQuestionImg }) =>
    isQuestionImg &&
    `

width:90%;
`}
  flex-direction:column;
  // align-items:center;
  .mark {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 3px solid #5ce0d2;
    margin-right: 10px;
  }
`;

export const OptionText = styled.div`
  overflow:auto;
`;

export const Wrapper = styled.div`
  .headingAssesment {
    display: flex;
    justify-content: flex-end;
  }
  .paginationBttns {
    display: flex;
    a {
      text-decoration: none;
      color: #5ce0d2;
      padding: 5px 10px;
      border: 1px solid #5ce0d2;

      margin: 0 10px;
    }
    a:hover {
      background-color: #5ce0d2;
    }

    li {
      list-style: none;
    }
  }
  .paginationActive a {
    background-color: #5ce0d2;
    color: #fff;
    box-sizing: border-box;
  }

  .child {
    display: flex;
    .totalQuestion {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      color: #fff;
      background-color: #5ce0d2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .filter {
      min-width: 200px;
      margin-left: 15px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

export const QuestionHelper = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  right: 20px;
  padding: 5px 15px;
  background-color: #5ce0d2;
  color: #fff;

  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const ImgContainer = styled.div`
width:100%;
max-height:130px;
box-sizing:border:box;
padding:0px;
display:flex;
justify-content:center;
img{
    max-width:100%;
    max-height:100%;
    overflow:hidden;
    border-radius:7px;
    box-sizing:border:box;
}
`;

export const ImgContent = styled.div`
  display: flex;
  width: 400px;
  height: 400px;
  padding: 0px;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  overflow: hidden;
  img {
    max-width:100%;
    max-height:100%;
    margin: auto;
  }
`;
