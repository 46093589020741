import React, { useState } from "react";

import axios from "axios";
import exportFromJSON from "export-from-json";

import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header } from "./Modal.style";
import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";

import { useAuth } from "../../Auth/Auth";

function ExportModal({ setModal, sources }) {
  const { readCookie, DisplaySnackbar } = useAuth();
  const [option, setOption] = useState(null);

  const getQuestionOptions = (options) => {
    const qOpts = {
      op1: "Option 1",
      op2: "Option 2",
      op3: "Option 3",
      op4: "Option 4",
    };
    const opts = {
      "Option 1": "",
      "Option 2": "",
      "Option 3": "",
      "Option 4": "",
    };
    options.forEach((option) => {
      const value = option.value.startsWith(`="`)
        ? option.value
        : `="${option.value || ""}"`;
      opts[qOpts[option.optionId]] = value;
    });

    return opts;
  };

  const getCorrectAnswers = (type, answers) => {
    /**
     * mcq:op1
     * mmcq:[op1,op2],
     * oneword:[
     *  {blankId, answer}
     * ],
     * fillup:[
     *  {blankId, answer:"asd"},
     *  {blankId, answer:"Add"}
     * ],
     * correctstatement:op1
     */
    var correctAnswer = null;
    switch (type) {
      case "mcq":
        correctAnswer = answers[type];
        break;
      case "mmcq":
        correctAnswer = answers[type].join(",");
        break;
      case "oneword":
        correctAnswer = answers[type].map((blank) => blank.answer).join(",");
        break;
      case "fillup":
        correctAnswer = answers[type].map((blank) => blank.answer).join(",");
        break;
      case "correctstatement":
        correctAnswer = answers[type];
        break;
    }

    return correctAnswer;
  };
  const getQuestionImages = (images) => {
    const imageFields = {
      questionImg: "Question Image",
      op1: "Option 1 Image",
      op2: "Option 2 Image",
      op3: "Option 3 Image",
      op4: "Option 4 Image",
    };
    const imagesLink = {
      "Question Image": "",
      "Option 1 Image": "",
      "Option 2 Image": "",
      "Option 3 Image": "",
      "Option 4 Image": "",
    };

    for (let field in imageFields) {
      const imgField = imageFields[field];
      imagesLink[imgField] = images[field] || "";
    }
    return imagesLink;
  };

  const handleSubmit = async () => {
    if (option === null) {
      DisplaySnackbar("Please choose anyone option", "error");
      return;
    }

    const source = sources[option];
    if (!source) {
      DisplaySnackbar(
        "Something went wrong. Refresh page and try again",
        "error"
      );
      return;
    }

    DisplaySnackbar("Please wait exporting data takes time", "info");
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/questions${source.api}&export=true`,
      {
        headers: {
          token: `${readCookie("token")}`,
        },
      }
    );

    if (res.data.status !== "success") {
      DisplaySnackbar(res.data.message, "error");
      return;
    }

    console.log(res.data.data.questions);

    exportFromJSON({
      data: res.data.data.questions.map((question) => {
        return {
          Subject: question.subjectId.name,
          Chapter: question.chapterId.name,
          Topic: question.topicId.name,
          Question: question.question,
          "Question Type": question.questionType,
          Cognitive: question.cognitive,
          Difficulty: question.difficulty,
          ...getQuestionOptions(question.options),
          "Correct Answers": getCorrectAnswers(
            question.questionType,
            question.correctAnswers
          ),
          ...getQuestionImages(question.images || {}),
          Visibility: question.visibilityStatus,
          QuestionId: question._id,
        };
      }),
      exportType: exportFromJSON.types.xls,
      fileName: sources[option].path,
      withBOM: true,
    });
    setModal(false);
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <Header>
          <h2>Export</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <p>Choose anyone option to export questions</p>
        <div style={{ margin: "10px 0", display: "flex" }}>
          <input
            type="radio"
            name="export-content"
            onChange={() => setOption(0)}
            style={{ display: "block", margin: "auto 20px auto 0" }}
          />
          <label style={{ fontSize: "1.1rem" }}>
            Export <b>{sources[0].name}</b>
          </label>
        </div>
        {sources[1].name && (
          <div style={{ margin: "10px 0", display: "flex" }}>
            <input
              type="radio"
              name="export-content"
              onChange={() => setOption(1)}
              style={{ display: "block", margin: "auto 20px auto 0" }}
            />
            <label style={{ fontSize: "1.1rem" }}>
              Export <b>{sources[1].name}</b>
            </label>
          </div>
        )}
        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          <Button onClick={handleSubmit}>Export</Button>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default ExportModal;
