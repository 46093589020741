import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  BoardsContainer,
  BoardsHeader,
  ButtonWrapper,
  Button,
} from "./Boards.style";
import { useNavigate } from "react-router-dom";
import HeaderPage from "../../Header/HeaderPage";
import {
  DashboardHeaderWrapper,
  DashboardHeader,
} from "../../Dashboard/Dashboard.style";
import InputComponent from "../../Input/InputComponent";
import ProfileImg from "../../../images/profileImg.svg";
import { Line } from "../../Dashboard/Dashboard.style";
import CommonComponent from "../../CommonComponent/CommonComponent";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import exportFromJSON from "export-from-json";

// import AddBoard from '../../Modal/AddBoard';
import { courses } from "../../../Api/api";
import { useAuth } from "../../../Auth/Auth";
import axios from "axios";
import AddModalComponent from "../../Modal/AddModalComponent";
import { subjects, Board, Class } from "../../../Api/api";

const leftHeader = (countryName) => {
  return (
    <div>
      <Link
        style={{ listStyle: "none", color: "black", textDecoration: "none" }}
        to="/courses"
      >
        <span>Courses</span>
      </Link>{" "}
      {` > `}
      <span>{countryName}</span>
    </div>
  );
};

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};


const Boardlabel = [
  [
    {
      label: "BOARD",
      placeholder: "Enter Board",
      name: "name",

      width: "100%",
    },
  ],
];
const Classlabel = [
  [
    {
      label: "CLASS",
      placeholder: "Enter Class",
      name: "name",

      width: "100%",
    },
  ],
];

function Boards() {
  const navigation = useNavigate();
  const { readCookie, DisplaySnackbar, imgUrl } = useAuth();
  const [switchLang, setSwitchLang] = useState(0);
  const [modal, setModal] = useState(false);
  const [allBoard, setAllBoard] = useState([]);
  const [BoardCourses, setBoardCourses] = useState([]);
  const [type, setType] = useState("");
  const [EditId, setEditId] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [activeBoardId, setActiveBoardId] = useState("");
  const [FilterClass, setFilterClass] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [search, setSearch] = useState("");
  const [tabActive, setTabActive] = useState(0);
  const { state } = useLocation();
  const [render, setRender] = useState(false);
  const [edit, setEdit] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const handleEdit = (editId) => {
    setHeaderText("Edit");
    setEdit(true);
    setModal(true);
    setEditId(editId);
  };

  const { id, countryName } = useParams();
  const handleOnClick = (classId) => {
    let bordname = allBoard.find((item) => item._id === activeBoardId).name;
    let className = BoardCourses.find((item) => item._id === classId).name;
    // localStorage.setItem("classId",classId);
    navigation(
      `/courses/${fixName(countryName)}/${id}/${fixName(bordname)}/${activeBoardId}/${fixName(className)}/${classId}`,
      {
        state: { lang: `${switchLang === 0 ? "english" : "hindi"}` },
      }
    );
  };

  useEffect(() => {
    if (search) {
      setBoardCourses(
        FilterClass.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setBoardCourses(FilterClass);
    }
  }, [search]);

  useEffect(() => {
    axios
      .get(courses.getCountryBoard(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setAllBoard(res.data.data.country.boards);
      })
      .catch((err) => console.log(err));
  }, []);

  const onBoardChange = (id) => {
    setActiveBoardId(id);
    // localStorage.setItem('boardName',allBoard.find(item=>item._id===id).name);
    // localStorage.setItem('boardId',id);
    axios
      .get(courses.getBoardContent(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setBoardCourses(res.data.data.board.courses);
        setFilterClass(res.data.data.board.courses);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (allBoard.length) {
      let bId;
      if (state?.boardID) {
        bId = state.boardID;
        let index = allBoard.findIndex((item) => item._id === state.boardID);
        // console.log(index);
        setTabActive(index);
      } else {
        bId = allBoard[0]._id;
      }
      onBoardChange(bId);
    }
  }, [allBoard]);

  useEffect(() => {
    if (!modal) {
      setFormData({ name: "" });
    }
  }, [modal]);

  const handleCreateClass = () => {
    if (!formData.name) {
      DisplaySnackbar("Add Name First", "error");
      return;
    }
    axios
      .post(
        Class.createClass,
        { name: formData.name, boardId: activeBoardId },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setBoardCourses([
          ...BoardCourses,
          { name: formData.name, _id: res.data.data.course._id },
        ]);
        setModal(false);
        DisplaySnackbar("Class Added", "success");
      })
      .catch((err) => console.log(err));
  };

  const handleCreateBoard = () => {
    if (!formData.name) {
      DisplaySnackbar("Add Name First", "error");
      return;
    }
    axios
      .post(
        Board.addBoard,
        { name: formData.name, countryId: id },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setAllBoard([
          ...allBoard,
          { name: formData.name, _id: res.data.data.board._id },
        ]);
        setModal(false);
        DisplaySnackbar("Board Added");
      })
      .catch((err) => console.log(err));
  };

  const editClass = () => {
    if (!formData.name) {
      DisplaySnackbar("Enter ClassName", "error");
      return;
    }
    axios
      .patch(
        Class.updateClass(EditId),
        { name: formData.name },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setBoardCourses([
          ...BoardCourses.map((item) =>
            item._id === formData._id ? { ...item, name: formData.name } : item
          ),
        ]);
        setModal(false);
        DisplaySnackbar("Class Edited", "success");
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteClass = (id) => {
    axios
      .delete(Class.deleteClass(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Class deleted", "success");
          handleupdateClassList(id);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateClassList = (id) => {
    const filteredData = BoardCourses.filter((i) => i._id !== id);
    setBoardCourses(filteredData);
  };

  const exportFile = () => {
    const data = BoardCourses.map((item) => {
      return {
        Id: item._id,
        Name: item.name,
        "Total Subjects": item.totalSubjects,
      };
    });
    const fileName = "Classes";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
    DisplaySnackbar("file Exported", "success");
  };

  const handleCreate = () => {
    if (headerText === "Add Board") {
      handleCreateBoard();
    } else if (headerText === "Add Class") {
      handleCreateClass();
    } else if (headerText === "Edit") {
      editClass();
    }
  };

  const EditBoard = () => {
    if (!formData.name) {
      DisplaySnackbar("Add Board Name First", "error");
      return;
    }
    axios
      .patch(
        Board.editBoard(formData._id),
        {
          name: formData.name,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setModal(false);
        DisplaySnackbar("Board Edited", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleDeleteBoard = (id) => {
    axios
      .delete(Board.deleteBoard(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Board deleted", "success");
          handleupdateBoardList(id);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
          // DisplaySnackbar("Board Not Found ", "error");
        }
      });
  };

  const handleupdateBoardList = (id) => {
    const filteredData = allBoard.filter((i) => i._id !== id);
    setAllBoard(filteredData);
  };

  const handleEditboard = (id) => {
    const boardToEdit = allBoard.find((item) => item._id === id);
    setEditId(id);
    setHeaderText("Edit Board");
    setModal(true);
    setFormData(boardToEdit || {});
    setType("BOARD");
  };
  const handleEditclass = (id) => {
    const classToEdit = BoardCourses.find((item) => item._id === id);
    setEditId(id);
    setHeaderText("Edit Class");
    setModal(true);
    setFormData(classToEdit || {});
    setType("CLASS");
  };

  return (
    <BoardsContainer>
      <DashboardHeaderWrapper minHeight="90px">
        <DashboardHeader>
          <div>
            <h1>Courses</h1>
          </div>

          <div>
            <InputComponent
              val={search}
              setVal={setSearch}
              type="text"
              search
              placeholder="Search..."
            />
            <Link to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImg}
                alt="profileimg"
              />
            </Link>
          </div>
        </DashboardHeader>
        <DashboardHeader></DashboardHeader>
        <HeaderPage close={false} leftHeader={leftHeader(countryName)} />
      </DashboardHeaderWrapper>
      <Line />
      <BoardsHeader>
        <div className="firstClass">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setType("BOARD");
              setHeaderText("Add Board");
              setEdit(false);
            }}
            className="child iconContainer"
          >
            {" "}
            <AddBoxOutlinedIcon className="icon" /> Add Board
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setType("CLASS");
              setHeaderText("Add Class");
              setEdit(false);
            }}
            className="child iconContainer"
          >
            <AddBoxOutlinedIcon className="icon" /> Add Class
          </div>
        </div>
        <div className="secondClass">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => exportFile()}
            className="child iconContainer"
          >
            <SystemUpdateAltOutlinedIcon className="icon" /> Export Csv
          </div>
          <div className="child">
            <ButtonWrapper>
              <Button
                onClick={() => setSwitchLang(0)}
                first
                active={switchLang === 0}
              >
                English
              </Button>
              <Button
                onClick={() => setSwitchLang(1)}
                second
                active={switchLang === 1}
              >
                Hindi
              </Button>
            </ButtonWrapper>
          </div>
        </div>
      </BoardsHeader>
      <Line />
      <CommonComponent
        setEdit={setEdit}
        handleEdit={handleEdit}
        onTabChange={onBoardChange}
        handleOnClick={handleOnClick}
        showDisable={true}
        type="class"
        tab={allBoard}
        tabContent={BoardCourses}
        tabActive={tabActive}
        isBoard={true}
        isClass={true}
        handleDeleteBoard={handleDeleteBoard}
        handleEditboard={handleEditboard}
        handleEditclass={handleEditclass}
        handleDeleteClass={handleDeleteClass}
      />
      {modal && (
        <AddModalComponent
          formData={formData}
          buttonText={edit ? "Edit" : "Create"}
          headerText2={headerText === "BOARD" ? "BOARD" : "CLASS"}
          setFormData={setFormData}
          handleCreate={handleCreate}
          headerText={headerText}
          setModal={setModal}
          label={type === "BOARD" ? Boardlabel : Classlabel}
          isBoard={true}
          EditBoard={EditBoard}
          modalType={type === "BOARD" ? "BOARD" : "CLASS"}
          editClass={editClass}
        />
      )}
    </BoardsContainer>
  );
}

export default Boards;
